import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UserFavCountsResponse, UserFavsResponse} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {ReportRequest} from "../../model/requests";

@Injectable()
export class UserBlockService {

    constructor(
        private http: HttpClient,
    ) {
    }

    block(userId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/block`, {userId: userId})
    }

    unblock(userId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/unblock`, {userId: userId});
    }

    report(request: ReportRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/report`, request);
    }
}
